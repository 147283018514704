import Vue from 'vue';

import GAuth from '@/packages/googleAuthPackage';

const config = {
  clientId: `${import.meta.env.VITE_GOOGLE_CLIENT_ID}`,
  scope: 'email profile',
  isOneTapEnabled: window.__VETSTER.canShowGoogleOneTap,
};

const initialize = () => Vue.prototype.$gAuth.initializeButton(config);

export function installGooglePlugin() {
  Vue.use(GAuth, config);
}

export function initializeGooglePlugin() {
  if (Vue.prototype.$gAuth) {
    initialize();
  } else {
    installGooglePlugin();

    // There's a timeout of 500ms to set google = window.google;
    setTimeout(initialize, 1000);
  }
}
