import Vue from 'vue';

import SocialiteProvider from '@/enums/SocialiteProvider';
import googleAnalyticsTrack from '@/packages/googleAnalyticsTrack';
import store from '@/store';

export default async function googleOneTap(response) {
  await store.dispatch('socialite/authenticate', {
    provider_type: SocialiteProvider.googleOneTap.value,
    access_token: response.credential,
    platform: 'WEB',
  })
    .then(async () => {
      if (await store.state.$auth.authenticate()) {
        await store.dispatch('checkAuthAndUser');
        Vue.prototype.$analytics.track(
          {
            event: 'ga_event',
            eventName: 'gx:identity:login:submit-success',
          },
          {
            type: SocialiteProvider.googleOneTap.value,
          },
        );

        store.$app.toast(store.$app.$t('login.welcome-back'));

        googleAnalyticsTrack({
          event: 'login',
          userId: store.state.user?.uuid || null,
        });
      } else if (await store.getters['socialite/isOpenForRegistration']) {
        store.dispatch('authModal/setAuthModalMode', 'socialite-register');
      }
    })
    .catch((error) => {
      if (error?.response?.data?.data?.requires_verification) {
        store.dispatch('authModal/setAuthModalMode', 'socialite-register');
        store.commit('emailVerification/setSocialite', {
          provider_type: SocialiteProvider.googleOneTap.value,
          access_token: response.credential,
        });
        store.commit('setXsrfToken', error.response);
        store.commit('emailVerification/showEmailVerification', true);
      } else {
        store.$app.toast(store.$app.$t('login.errors.something-went-wrong-with-social-connection'), {
          status: 'error',
        });
      }
    });
}
